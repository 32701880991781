//
// Variables
// --------------------------------------------------

// Scope
//
$scope: 'ls', '!critical', 'eng';

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #242424;
$gray-darkest: #222;
$gray-darker: lighten($gray-base, 8%);
$gray-dark: lighten($gray-base, 20%);
$gray: lighten($gray-base, 33.5%);
$gray-light: lighten($gray-base, 70%);
$gray-lighter: #f5f5f5;
$gray-extralight: lighten($gray-base, 85%);
$gray-lightest: #fafafa;
$brand-primary: #003978;
$brand-secondary: #fdc800;
$gr-red: #840303;
$gr-blue: $brand-primary;
$dark-blue: #012D53;
$light-blue: #295f8d;
$blue-border: #93aec5;
$mad: #099;
$soirmag: #c92530;
$sosoir: #2e3032;
$mad: #099;
$red-scribble: #982215;
$brand-success:         #07b081;
//$brand-info:            #5bc0de;
//$brand-warning:         #f0ad4e;
//$brand-danger:          #d9534f;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Roboto', sans-serif;
$gr-font-family-sans-serif-headings: 'Fira Sans', sans-serif;
$font-family-serif: MillerDisplay, Georgia, "Times New Roman", Times, serif;
$font-condensed: 'Economica', sans-serif;
$font-family-premium: 'Myriad Pro', 'Helvetica Neue', arial, sans-serif;

// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
//$font-family-monospace:   Menlo, Monaco, Consolas, 'Courier New', monospace;
//$font-family-base:        $font-family-sans-serif;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-small: 12px;
//$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
//$font-size-small:         ceil(($font-size-base *.85)); // ~12px

$font-size-h1: $font-size-base * 3; //35px ~36px
$font-size-h2: floor(($font-size-base * 1.45)); // 21px ~24px
$font-size-h3: floor(($font-size-base * 1.3)); // 20px
$font-size-h4: ceil(($font-size-base * 1.2)); //17px ~18px
$font-size-h5:            15px;
//$font-size-h6:            ceil(($font-size-base *.85)); // ~12px

// Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.35; //1.428571429; // 20/14

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

//$btn-font-weight:                normal;

$btn-default-color: #333;
$btn-default-bg: $light;
$btn-default-border: $gray;

// Navbar collapse
// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: 10px;
// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Navbar
//
//##
$navbar-inverse-bg: $brand-primary;
$navbar-inverse-link-color: $light;
$grid-gutter-width: 24px;
$target-gutter: 30px;

// Specific variables
// -----------

$header-border-top-width: 7px;
$default-border-color: $gray-light;
$default-border: 1px solid $default-border-color;
$signal-color: $gray-dark;
$signal-color-text: $light;
$strokes-color: $gr-blue;

// DIRECT ALERT COLORS
$direct-alert-alerte-info: #cc0300;
$direct-alert-exclusif: #cc0300;
$direct-alert-direct: #cc0300;

//	Aside article
$aside-width: 310px;

//	Direct
$direct-width: 300px;
$content-width: 600px + $grid-gutter-width * 3.5;
$content-max-width: $content-width + 300px + $direct-width; //1284px
$html-max-width: $content-max-width + $grid-gutter-width * 2; //1332px
$screen-xlg-min: 1333px; //$html-max-width + 1px;

// SIZE
$header-height: 96px;
$header-xs-height: 45px;
$header-small-height: 36px;

// MINUS SERVICES
$header-height-services: $header-height + 100;
$header-small-height-services: $header-small-height + 100;

// Social links
$btn-social-size: 40px;
$btn-back-size: 40px;
$ads-imu-halfpage-sticky: 600px;

// ARCHE / WALLPAPER
$arche-max-width: 1048px;

//Logo popin
$logo-popin : "/sites/all/themes/engbootstrap_lesoir/images/lesoir_blanc.svg";