// ##### CONFIG
//<= 320
$xxxs: '(max-width: ' + $screen-xxs + ')';
$xxxs-d: '(max-device-width: ' + $screen-xxs + ')';
$xxxs-d-portrait: '(max-device-width: ' + $screen-xxs +
    ') and (orientation: portrait)';

// <= 480
$xxs: '(max-width: ' + $screen-xs + ')';
$xxs-d: '(min-device-width :  ' + $screen-xxs + ' ) and (max-device-width: ' +
    $screen-xs - 1 + ')';
$xxs-d-portrait: '(max-device-width: ' + $screen-xs +
    ') and (orientation: portrait)';

$xs: '(max-width: ' + $screen-xs-max + ')'; // <= 767
$xs-d: '(max-device-width: ' + $screen-xs-max + ')'; // <= 767
$xs-d-portrait: '(max-device-width: ' + $screen-xs-max +
    ') and (orientation: portrait)'; // <= 767
$xs-d-landscape: '(max-device-width: ' + $screen-xs-max +
    ') and (orientation: landscape)'; // <= 767

$sm: '(min-width: ' + $screen-sm-min + ') and (max-width: ' + $screen-sm-max +
    ')'; // 768 -> 991
$sm-d: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' +
    $screen-sm-max + ')'; // 768 -> 991
$sm-d-portrait: '(min-device-width: ' + $screen-sm-min +
    ') and (max-device-width: ' + $screen-sm-max +
    ') and (orientation: portrait)'; // 768 -> 991
$sm-d-landscape: '(min-device-width: ' + $screen-sm-min +
    ') and (max-device-width: ' + $screen-sm-max +
    ') and (orientation: landscape)'; // 768 -> 991

$md: '(min-width: ' + $screen-md-min + ') and (max-width: ' + $screen-md-max +
    ')'; // 992 -> 1199
$md-d: '(min-device-width: ' + $screen-md-min + ') and (max-device-width: ' +
    $screen-md-max ')'; // 992 -> 1199
$md-d-portrait: '(min-device-width: ' + $screen-md-min +
    ') and (max-device-width: ' + $screen-md-max ') and (orientation: portrait)'; // 992 -> 1199
$md-d-landscape: '(min-device-width: ' + $screen-md-min +
    ') and (max-device-width: ' + $screen-md-max
    ') and (orientation: landscape)'; // 992 -> 1199

$lg: '(min-width: ' + $screen-lg-min + ')'; // 1200 <

// >= 1920
$xlg: '(min-width: ' + $screen-xlg-min + ')';

$fullhd: '(min-width: 1770px)';

$xssm: '(max-width: ' + $screen-sm-max + ')'; // 768 -> 991;
$xssm-d: '(max-device-width: ' + $screen-sm-max + ')'; // 768 -> 991;
$xssm-d-portrait: '(max-device-width: ' + $screen-sm-max +
    ') and (orientation: portrait)'; // 768 -> 991;
$xssm-d-landscape: '(max-device-width: ' + $screen-sm-max +
    ') and (orientation: landscape)'; // 768 -> 991;

$mdlg: '(min-width: ' + $screen-md-min + ')'; // 992 >=;
$mdlg-d: '(min-device-width: ' + $screen-md-min + ')';
$mdlg-d-portrait: '(min-device-width: ' + $screen-md-min +
    ') and (orientation: portrait)';
$mdlg-d-landscape: '(min-device-width: ' + $screen-md-min +
    ') and (orientation: landscape)';

// 700 -> 1199
$smmd: '(min-width: ' + $screen-sm-min + ') and (max-width: ' + $screen-md-max +
    ')';
$smmd-d: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' +
    $screen-md-max + ')';
$smmd-d-portrait: '(min-device-width: ' + $screen-sm-min +
    ') and (max-device-width: ' + $screen-md-max +
    ') and (orientation: portrait)';
$smmd-d-landscape: '(min-device-width: ' + $screen-sm-min +
    ') and (max-device-width: ' + $screen-md-max +
    ') and (orientation: landscape)';

// <= 1199
$xssmmd: '(max-width: ' + $screen-md-max + ')';
$xssmmd-d: '(max-device-width: ' + $screen-md-max + ')';
$xssmmd-d-portrait: '(max-device-width: ' + $screen-md-max +
    ') and (orientation: portrait)';
$xssmmd-d-landscape: '(max-device-width: ' + $screen-md-max +
    ') and (orientation: landscape)';

// >= 700
$smmdlg: '(min-width: ' + $screen-sm-min + ')';
$smmdlg-d: '(min-device-width: ' + $screen-sm-min + ')';
$smmdlg-d-portrait: '(min-device-width: ' + $screen-sm-min +
    ') and (orientation: portrait)';
$smmdlg-d-landscape: '(min-device-width: ' + $screen-sm-min +
    ') and (orientation: landscape)';

// MIXINS

@mixin xxxs($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xxxs-d !global;
    }

    @else if $mode == dl {
        $query: $xxxs-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $xxxs-d-portrait !global;
    }

    @else {
        $query: $xxxs !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin xxs($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xxs-d !global;
    }

    @else if $mode == dl {
        $query: $xxs-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $xxs-d-portrait !global;
    }

    @else {
        $query: $xxs !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin xs($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xs-d !global;
    }

    @else if $mode == dl {
        $query: $xs-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $xs-d-portrait !global;
    }

    @else {
        $query: $xs !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin sm($mode: null, $argument: null) {
    @if $mode == d {
        $query: $sm-d !global;
    }

    @else if $mode == dl {
        $query: $sm-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $sm-d-portrait !global;
    }

    @else {
        $query: $sm !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin md($mode: null, $argument: null) {
    @if $mode == d {
        $query: $md-d !global;
    }

    @else if $mode == dl {
        $query: $md-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $md-d-portrait !global;
    }

    @else {
        $query: $md !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin lg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $lg-d !global;
    }

    @else if $mode == dl {
        $query: $lg-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $lg-d-portrait !global;
    }

    @else {
        $query: $lg !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin xlg($argument: null) {
    @media #{$xlg} #{$argument} {
        @content;
    }
}

@mixin fullhd($argument: null) {
    @media #{$fullhd} #{$argument} {
        @content;
    }
}

@mixin dpi($ratio: 1.5, $resolution: 144dpi, $argument: null) {
    @media (-webkit-min-device-pixel-ratio: #{$ratio}), (min-resolution: #{$resolution}) {
        @content;
    }
}

@mixin touch() {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin notouch() {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin edge() {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

@mixin xssm($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xssm-d !global;
    }

    @else if $mode == dl {
        $query: $xssm-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $xssm-d-portrait !global;
    }

    @else {
        $query: $xssm !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin smmd($mode: null, $argument: null) {
    @if $mode == d {
        $query: $smmd-d !global;
    }

    @else if $mode == dl {
        $query: $smmd-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $smmd-d-portrait !global;
    }

    @else {
        $query: $smmd !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin mdlg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $mdlg-d !global;
    }

    @else if $mode == dl {
        $query: $mdlg-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $mdlg-d-portrait !global;
    }

    @else {
        $query: $mdlg !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin xssmmd($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xssmmd-d !global;
    }

    @else if $mode == dl {
        $query: $xssmmd-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $xssmmd-d-portrait !global;
    }

    @else {
        $query: $xssmmd !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin smmdlg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $smmdlg-d !global;
    }

    @else if $mode == dl {
        $query: $smmdlg-d-landscape !global;
    }

    @else if $mode == dp {
        $query: $smmdlg-d-portrait !global;
    }

    @else {
        $query: $smmdlg !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}
