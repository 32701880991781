// Scopes
//
$scope-list: (
    BE: (
        'ge',
        'ls',
        'sb',
        'sp'
    ),

    ENA: (
        'ard',
        'an',
        'art',
        'ber',
        'bou',
        'cp',
        'dun',
        'ee',
        'if',
        'jf',
        'lal',
        'lc',
        'ls',
        'mes',
        'mon',
        'ne',
        'nl',
        'sb',
        'sp',
        'tou',
        'un',
        'vdn'
    ),

    ENG: (
        'ard',
        'cp',
        'ee',
        'ge',
        'lc',
        'ls',
        'sp',
        'un'
    ),

    FR: (
        'ard',
        'an',
        'art',
        'ber',
        'bou',
        'cp',
        'dun',
        'ee',
        'if',
        'jf',
        'lal',
        'lc',
        'mes',
        'mon',
        'ne',
        'nl',
        'tou',
        'un',
        'vdn'
    ),

    NLH: (
        'art',
        'ber',
        'bou',
        'dun',
        'if',
        'jf',
        'lal',
        'mon',
        'nl',
        'tou'
    ),

    PRM: (
        'an',
        'cp'
    ),

    REM: (
        'ard',
        'ee',
        'lc',
        'un'
    ),

    VDNE: (
        'ne',
        'vdn'
    )
);


// Couleurs
//
// Test de documentation
//
// Styleguide SCSS - Variables - Couleurs

$light: #fff;
$dark: #000;

$brand-primary: darken(#428bca, 6.5%); // #337ab7
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

$gray-base: #242424;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 70%); // #777
$gray-lighter: lighten($gray-base, 80%); // #eee

//$gr-white:  $light;
$gr-red: #840303;
//$gr-black: $dark;
$green: #53c153;
$yellow: #fdd22e;
$red: #b50000;
$hover-color: lighten($brand-primary, 30%); // #eee

// SOCIAL COLORS
$blue-facebook: #1877f2;
$blue-twitter: #4099ff;
$blue-vimeo: #1ab7ea;
$orange-slideshare: #ffa400;
$blue-dailymotion: #0066a6;
$blue-storify: #3a98d9;
$blue-ustream: #539eec;
$blue-deezer: #1990db;
$orange-rtl-info: #e79201;
$red-scribble: #ef1200;
$blue-linkedin: #0274b3;
$red-pinterest: #cb2027;
$red-youtube: #cc181e;
$blue-google: #0266c8;
$red-google: #f90101;
$yellow-google: #f2b50f;
$green-google: #00933b;
$green-android: #a3c240;
$orange-rss: #f18b32;
$green-whatsapp: #25d366;
$blue-messenger: #0084ff;

//== Scaffolding
//
//## Settings for some of the most global styles.

// Background color for `<body>`.
$body-bg: $light;
// Global text color on `<body>`.
$text-color: $gray-dark;

// Global textual link color.
$link-color: $brand-primary;
// Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
// Link hover decoration.
$link-hover-decoration: underline;

// Typographies
//
// typo should be pretty and seen from far away.
//
// Markup: <div class="{{modifier_class}}">Some text, preferrably anything but Lorem ipsum in there
// <ul><li></li></ul>
// </div>
//
// .lightblue  - LightBlue text box.
// .red        - Red text box.
// .orange     - Orange text box.
// .green      - Green text box.
//
// Styleguide SCSS - Variables - Typographies

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-title: Georgia, 'Times New Roman', Times, serif;
$font-nav: $font-title;
$font-text: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-bold: $font-text;
$font-italic: $font-text;

$font-size-base: 14px;
$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * .85)); // ~12px
$font-size-nav: ceil(($font-size-base * 1.25)); // ~12px

$line-height-base: 1.428571429; // 20/14
$line-height-h1: $line-height-base;
$line-height-h2: $line-height-base;
$line-height-h3: $line-height-base;
$line-height-h4: $line-height-base;
$line-height-h5: $line-height-base;
$line-height-h6: $line-height-base;

$font-weight-base: if($font-bold == $font-text, 700, false);
$font-style-base: if($font-italic == $font-text, italic, false);

$gr-font-family-sans-serif-headings: Georgia, 'Times New Roman', Times, serif;
$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * .85)); // ~12px

// Computed 'line-height' (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

// By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

// Load fonts from this directory.
$icon-font-path: '../fonts/';
// File name for all font files.
$icon-font-name: 'glyphicons-halflings-regular';
// Element ID within SVG icon file.
$icon-font-svg-id: 'glyphicons_halflingsregular';

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;
$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;
$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;
$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;
$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;
$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 3px;

// Global color for active items (e.g., navs or dropdowns).
$component-active-color: $light;
// Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

// Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
// Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

// Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
// Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

// Default background color used for all tables.
$table-bg: transparent;
// Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9;
// Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

// Border color for table and cell borders.
$table-border-color: #ddd;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal;
$btn-default-color: #333;
$btn-default-bg: $light;
$btn-default-border: $gray;
$btn-primary-color: $light;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);
$btn-success-color: $light;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);
$btn-info-color: $light;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);
$btn-warning-color: $light;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);
$btn-danger-color: $light;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);
$btn-link-disabled-color: $gray-light;

//== Forms
//
//##

// `<input>` background color
$input-bg: $light;
// `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

// Text color for `<input>`s
$input-color: $gray;
// `<input>` border color
$input-border: #ccc;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
// Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
// Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
// Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

// Border color for inputs on focus
$input-border-focus: #66afe9;

// Placeholder text color
$input-color-placeholder: #999;

// Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
// Large `.form-control` height
$input-height-large: (
    ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
    2
);
// Small `.form-control` height
$input-height-small: (
    floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
    2
);

// `.form-group` margin
$form-group-margin-bottom: 15px;
$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

// Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
// Border color for textual input addons
$input-group-addon-border-color: $input-border;

// Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//== Dropdowns
//
//## Dropdown menu container and contents.

// Background for the dropdown menu.
$dropdown-bg: $light;
// Dropdown menu `border-color`.
$dropdown-border: rgba($dark, .15);
// Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
// Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

// Dropdown link text color.
$dropdown-link-color: $gray-dark;
// Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
// Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5;

// Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
// Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

// Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

// Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

// Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: $dark;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal: 1040;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 320px;
// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 700px;
$screen-sm-min: $screen-sm;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-xlg-min: 1781px;

//== Grid system
//
//## Define your custom responsive grid.

// Number of columns in the grid.
$grid-columns: 12;
// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
// For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
// For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
// For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height: 340px;
$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%);
$navbar-inverse-bg: #222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: lighten($gray-light, 15%);
$navbar-inverse-link-hover-color: $light;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: $light;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: $light;
$navbar-inverse-toggle-border-color: #333;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;
$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-tabs-border-color: #ddd;
$nav-tabs-link-hover-border-color: $gray-lighter;
$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: #ddd;
$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

//== Pagination
//
//##

$pagination-color: $link-color;
$pagination-bg: $light;
$pagination-border: #ddd;
$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;
$pagination-active-color: $light;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;
$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: $light;
$pagination-disabled-border: #ddd;

//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;
$pager-hover-bg: $pagination-hover-bg;
$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;
$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-lighter;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);
$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);
$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);
$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

//== Tooltips
//
//##

// Tooltip max width
$tooltip-max-width: 200px;
// Tooltip text color
$tooltip-color: $light;
// Tooltip background color
$tooltip-bg: $dark;
$tooltip-opacity: .9;

// Tooltip arrow width
$tooltip-arrow-width: 5px;
// Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

// Popover body background color
$popover-bg: $light;
// Popover maximum width
$popover-max-width: 276px;
// Popover border color
$popover-border-color: rgba($dark, .2);
// Popover fallback border color
$popover-fallback-border-color: #ccc;

// Popover title background color
$popover-title-bg: darken($popover-bg, 3%);

// Popover arrow width
$popover-arrow-width: 10px;
// Popover arrow color
$popover-arrow-color: $popover-bg;

// Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
// Popover outer arrow color
$popover-arrow-outer-color: fadein($popover-border-color, 5%);
// Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken(
    $popover-fallback-border-color,
    20%
);

//== Labels
//
//##

// Default label background color
$label-default-bg: $gray-light;
// Primary label background color
$label-primary-bg: $brand-primary;
// Success label background color
$label-success-bg: $brand-success;
// Info label background color
$label-info-bg: $brand-info;
// Warning label background color
$label-warning-bg: $brand-warning;
// Danger label background color
$label-danger-bg: $brand-danger;

// Default label text color
$label-color: $light;
// Default text color of a linked label
$label-link-hover-color: $light;

//== Modals
//
//##

// Padding applied to the modal body
$modal-inner-padding: 15px;

// Padding applied to the modal title
$modal-title-padding: 15px;
// Modal title line-height
$modal-title-line-height: $line-height-base;

// Background color of modal content area
$modal-content-bg: $light;
// Modal content border color
$modal-content-border-color: rgba($dark, .2);
// Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

// Modal backdrop background color
$modal-backdrop-bg: $dark;
// Modal backdrop opacity
$modal-backdrop-opacity: .5;
// Modal header border color
$modal-header-border-color: #e5e5e5;
// Modal footer border color
$modal-footer-border-color: $modal-header-border-color;
$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;
$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;
$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;
$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;
$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

//== Progress bars
//
//##

// Background color of the whole progress component
$progress-bg: #f5f5f5;
// Progress bar text color
$progress-bar-color: $light;
// Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

// Default progress bar color
$progress-bar-bg: $brand-primary;
// Success progress bar color
$progress-bar-success-bg: $brand-success;
// Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
// Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
// Info progress bar color
$progress-bar-info-bg: $brand-info;

//== List group
//
//##

// Background color on `.list-group-item`
$list-group-bg: $light;
// `.list-group-item` border color
$list-group-border: #ddd;
// List group border radius
$list-group-border-radius: $border-radius-base;

// Background color of single list items on hover
$list-group-hover-bg: #f5f5f5;
// Text color of active list items
$list-group-active-color: $component-active-color;
// Background color of active list items
$list-group-active-bg: $component-active-bg;
// Border color of active list elements
$list-group-active-border: $list-group-active-bg;
// Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

// Text color of disabled list items
$list-group-disabled-color: $gray-light;
// Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
// Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;
$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;

//== Panels
//
//##

$panel-bg: $light;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

// Border color for elements within panels
$panel-inner-border: #ddd;
$panel-footer-bg: #f5f5f5;
$panel-default-text: $gray-dark;
$panel-default-border: #ddd;
$panel-default-heading-bg: #f5f5f5;
$panel-primary-text: $light;
$panel-primary-border: $brand-primary;
$panel-primary-heading-bg: $brand-primary;
$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;
$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;
$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;
$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;

//== Thumbnails
//
//##

// Padding around the thumbnail image
$thumbnail-padding: 4px;
// Thumbnail background color
$thumbnail-bg: $body-bg;
// Thumbnail border color
$thumbnail-border: #ddd;
// Thumbnail border radius
$thumbnail-border-radius: $border-radius-base;

// Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
// Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;

//== Wells
//
//##

$well-bg: #f5f5f5;
$well-border: darken($well-bg, 7%);

//== Badges
//
//##

$badge-color: $light;
// Linked badge text color on hover
$badge-link-hover-color: $light;
$badge-bg: $gray-light;

// Badge text color in active nav link
$badge-active-color: $link-color;
// Badge background color in active nav link
$badge-active-bg: $light;
$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
// Breadcrumb background color
$breadcrumb-bg: #f5f5f5;
// Breadcrumb text color
$breadcrumb-color: #ccc;
// Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;
// Textual separator for between breadcrumb elements
$breadcrumb-separator: '/';

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba($dark, .6);
$carousel-control-color: $light;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-font-size: 20px;
$carousel-indicator-active-bg: $light;
$carousel-indicator-border-color: $light;
$carousel-caption-color: $light;

//== Close
//
//##

$close-font-weight: bold;
$close-color: $dark;
$close-text-shadow: 0 1px 0 $light;

//== Code
//
//##

$code-color: #c7254e;
$code-bg: #f9f2f4;
$kbd-color: $light;
$kbd-bg: #333;
$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;

//== Type
//
//##

// Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
// Text muted color
$text-muted: $gray-light;
// Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
// Headings small color
$headings-small-color: $gray-light;
// Blockquote small color
$blockquote-small-color: $gray-light;
// Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
// Blockquote border color
$blockquote-border-color: $gray-lighter;
// Page header border color
$page-header-border-color: $gray-lighter;
// Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
// Horizontal line color.
$hr-border: $gray-lighter;

//=============================================
//	General
//=============================================

$mediaGutter: 10px;

// NO MORE BORDER_RADIUS
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

// RATIO
$ratio-16-9: (9 / 16 * 100%);
$ratio-4-3: (3 / 4 * 100%);
$ratio-1-1: (100%);

// TOOLS WIDTH
$tools_width_of_full_width_page_tools: 55px;
$gr-full-width-pages-margin: 70px;

// SIZE
$content-max-width: 1190px;
$html-max-width: 1780px;

//=============================================
//	Header
//=============================================

$header-height: 50px;
$header-small-height: 36px;
$headroom-top-offset: 50px;
$header-border-top-width: 7px;

// HEADER Z-INDEX
$header-z-index: 20;
$navbar-header-z-index: 20; // main-menu

//=============================================
//	Sidebar
//=============================================

// SIDEBAR Z-INDEX
$header-sidebar-z-index--open: 22;
$header-sidebar-z-index--close: 4;

// SIDEBAR-LEFT Z-INDEX
$sidebar-left-z-index: 15;
$sidebar-left-z-index--collapsed-fluid: 21;

//=============================================
//	Direct
//=============================================
$direct-width: 260px;

//media query section top
$section-plus-top-screen: $content-max-width + $direct-width +
    $grid-gutter-width * 2;
