//$l-absolute-url: 'https://espace-abonnement.lesoir.be/sites/all/themes/custom/boutique/bootstrapMarketingLeSoir/img/sso';
$l-absolute-url: 'https://www.lesoir.be/sites/all/themes/engbootstrap_lesoir/images/sso';

//###########################
// ENGBOOTSTRAP_LESOIR IMPORT
//###########################

@import './www/scss/enabootstrap/project/variables';
@import './www/scss/enabootstrap/project/mixins';
@import './www/scss/enabootstrap/external/sidebar';

// VAR
@import 'main/project/variables';

// MIXINS
@import 'main/project/mixin/mixins';

//###########################
// BARRE SSO
//###########################

body {
    margin-top: 36px;
}

.sso {
    color: $light;
    display: inline-block;
    font-family: helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: 36px;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;

    a:visited,
    a {
        color: $light;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: block;
    }

    .sidebar {
        background: $gray-darker;
        padding-left: 0;
        top: 36px;
        width: 300px;

        li {
            li {
                display: block;
                font-size: $font-size-h4;
                padding-left: 15px;
                width: auto;
            }
        }
    }

    .nav {
        li {
            border-bottom: 1px inset #2c2c2c;
            display: block;
            float: none;
            width: auto;

            a {
                display: block;
                padding: 10px 20px;
                text-align: left;
            }
        }
    }

    .navbar-toggle.toggle-left {
        display: block;
        margin: 0;
        padding: 0;
    }

    .expanded {
        display: block;
        position: relative;
        width: auto;

        transition: all .3s;

        > .btn-nav {
            background: url('#{$l-absolute-url}/menu-more.svg') center left 5px no-repeat;
            background-size: auto 22px;
            color: #aaa;
            height: 52px;
            position: absolute;
            right: 0;
            top: 0;
            width: 52px;

            transition: all .3s;

            &:hover {
                color: $light;
            }
        }

        > .nav {
            height: 0;
            overflow: hidden;
            padding: 0;
            width: auto;

            transition: all .3s;
        }

        &.has-nav-open {
            > .btn-nav {
                background: url('#{$l-absolute-url}/menu-more.svg') center left 5px no-repeat;
                background-size: auto 22px;
            }
        }
    }
}

.sso-menu {
    display: none;
    left: 0;
    position: absolute;
    text-indent: 0;
    top: 100%;
    width: 200px;
}

.sso-menu-item {
    line-height: 30px;
    padding-left: $grid-gutter-width;
}

.sso-user-menu,
.sso-main-menu {
    &:hover,
    &.open {
        .sso-menu {
            display: block;
        }
    }
}

.sso-main-menu {
    float: left;
    //padding-top: 7px;
    font-size: 22px;
    padding-left: $grid-gutter-width / 2;

    .icon-menu {
        background: url('#{$l-absolute-url}/menu.svg') center left 5px no-repeat;
        background-size: auto 22px;
        display: block;
        height: 36px;
        width: 33px;
    }
}

.sso-main-icon {
    display: block;
    height: 36px;
    padding-top: 7px;

    span {
        background: $light;
        display: block;
        height: 3px;
        margin-bottom: 6px;
        width: 30px;
    }
}

.sso-logo {
    float: left;
    padding: 3px;

    img {
        display: block;
        height: 30px;
        width: 160px;
    }
}

.sso-user-menu {
    color: $brand-secondary;
    float: right;
    line-height: 36px;
    padding: 0 $grid-gutter-width*1.5 0 $grid-gutter-width / 2;
    position: relative;

    @include xxs {
        background: url('#{$l-absolute-url}/user.svg') center center no-repeat;
        background-size: auto 60%;
        padding: 0;
        text-indent: -999em;
    }

    // &::after {
    //     border-color: $brand-secondary transparent transparent transparent;
    //     border-style: solid;
    //     border-width: 6px 6px 0 6px;
    //     content: '';
    //     height: 0;
    //     position: absolute;
    //     right: 10px;
    //     top: 15px;
    //     width: 0;
    // }

    .sso-menu {
        left: auto;
        right: 0;
    }
}

.sso-non-connecte {
    background: $brand-primary;
    border-bottom: 1px solid lighten($brand-primary, 5%);

    .sso-logo {
        img {
            height: 20px;
            margin: 5px 0;
        }
    }

    .sso-user-menu {
        background: url('#{$l-absolute-url}/user.svg') center left 5px no-repeat;
        background-size: auto 50%;
        border-left: 1px solid $gray;
        padding: 0;
        text-indent: -999em;
        width: 60px;

        &:hover {
            background: $gray url('#{$l-absolute-url}/user.svg') center left 5px no-repeat;
            background-size: auto 50%;

            @include xxs {
                background: $gray url('#{$l-absolute-url}/user.svg') center left 5px no-repeat;
                background-size: auto 50%;
            }
        }
    }

    .sso-menu {
        background: $gray-dark;
    }

    .sso-menu-item {
        border-bottom: 1px solid $gray;

        &:hover {
            background: $gray;
        }
    }
}

.sso-connecte {
    background: $brand-primary;
    border-bottom: 1px solid lighten($brand-primary, 5%);

    .sso-user-menu {
        border-left: 1px solid lighten($brand-primary, 5%);

        &:hover {
            background: lighten($brand-primary, 10%);
            background-size: auto 50%;

            @include xxs {
                background: lighten($brand-primary, 10%) url('#{$l-absolute-url}/user.svg') center left 5px no-repeat;
                background-size: auto 50%;
            }
        }
    }

    .sso-user-label {
        color: $brand-secondary;
    }

    .sso-menu {
        background: $brand-primary;
    }

    .sso-menu-item {
        border-bottom: 1px solid lighten($brand-primary, 5%);

        &:hover {
            background: lighten($brand-primary, 5%);
        }
    }
}
