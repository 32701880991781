.sidebar {
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
    bottom: 0;
    display: block;
    max-height: none;
    min-height: 100%;
    overflow: auto;
    padding: 20px;
    position: fixed;
    top: 101px;
    z-index: 1000;

    // Left panel positioning classes
    &.sidebar-left {
        transform: translate3d(-100%, 0, 0);
    }

    &.sidebar-left.sidebar-open {
        transform: translate3d(0, 0, 0);
    }

    // TODO: TEST THIS! Right panel positioning classes
    &.sidebar-right {
        transform: translate3d(100%, 0, 0);
    }

    &.sidebar-right.sidebar-open {
        -moz-transform: none;
        transform: translate3d(0, 0, 0);
    }
}

.sidebar-left {
    left: 0;
}

.sidebar-right {
    right: 0;
}

// animation class - optional: without it the sidebar would just pop in and out
.sidebar-animate {
    transition: transform 300ms ease;
}

// .navbar.navbar-static .navbar-header {
//     float: left;
// }
// 
// .navbar .navbar-toggle.toggle-left {
//     float: left;
//     margin-left: 15px;
// }
// 
// .navbar .navbar-toggle.toggle-right {
//     float: right;
//     margin-right: 15px;
// }
// 
// .navbar .navbar-toggle.toggle-sidebar,
// [data-toggle='sidebar'] {
//     display: block;
// }

// css to override hiding the sidebar according to different screen sizes

// .row .sidebar.sidebar-left.sidebar-xs-show {
//     left: 0;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: none;
//     transform: translate3d(0, 0, 0);
// }
// 
// 
// // right sidebar is untested
// 
// .row .sidebar.sidebar-right.sidebar-xs-show {
//     right: 0;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: none;
//     transform: translate3d(0, 0, 0);
// }
// 
// @include smmdlg {
//     .row .sidebar.sidebar-left.sidebar-sm-show {
//         left: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
// 
//     // right sidebar is untested
//     .row .sidebar.sidebar-right.sidebar-sm-show {
//         right: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
// }
// 
// @include mdlg {
//     .row .sidebar.sidebar-left.sidebar-md-show {
//         left: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
//     .row .sidebar.sidebar-right.sidebar-md-show {
//         right: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
// }
// 
// @include lg {
//     .row .sidebar.sidebar-left.sidebar-lg-show {
//         left: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
// 
//     .row .sidebar.sidebar-right.sidebar-lg-show {
//         right: 0;
//         -webkit-transform: translate3d(0, 0, 0);
//         -moz-transform: none;
//         transform: translate3d(0, 0, 0);
//     }
// }
